var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row align-items-center justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-4 col-12"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Berdasar Nama"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '20%' : '' })})})}},{key:"cell(price)",fn:function(data){return [_vm._v(" "+_vm._s(parseInt(data.item.price).toLocaleString("id-ID"))+" ")]}},{key:"cell(isBundle)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.isBundle == 0)?_c('i',{staticClass:"fas fa-md fa-times px-0 text-danger"}):_vm._e(),(data.item.isBundle)?_c('i',{staticClass:"fas fa-md fa-check px-0 text-primary"}):_vm._e()])]}},{key:"cell(doctor_commission)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.doctor_commission)+" ")]}},{key:"cell(hospital_income)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.hospital_income)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(_vm.purpose == 'modalDoctor')?[_c('b-button',{staticClass:"mr-1 btn-primary",attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.chooseItem({
                id: data.item.id,
                name: data.item.name,
                price: data.item.price,
              })}}},[_vm._v("Pilih")])]:_vm._e(),(_vm.purpose != 'modalDoctor')?[_c('b-form-checkbox',{attrs:{"checked":data.item.checked,"id":`checkbox-pick-payment-category-${data.item.id}`,"name":`checkbox-pick-payment-category-${data.item.id}`},on:{"change":function($event){return _vm.chooseItem({
                id: data.item.id,
                name: data.item.name,
                price: data.item.price,
              })}}})]:_vm._e()],2)]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }